'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.assets.factory:AssetStatusManager

 # @description

###
angular
  .module 'mundoAdmin.assets'
  .factory 'AssetStatusManager', [
    'MundoDefaultManager'
    (MundoDefaultManager) ->
      AssetStatusManagerBase  =  new MundoDefaultManager()
      AssetStatusManagerBase.conf.url = 'assets/statuses'

      AssetStatusManagerBase
  ]
